<template lang="pug">
.chips
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.courseCity.length"
    data-test="c-chip-location"
    @click:close="removeFilterMultiple({field: 'courseCity'})"
  ).chip-common Course location: {{ courseLocations | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.cbrStatus.length"
    @click:close="removeFilterMultiple({field: 'cbrStatus'})"
  ).chip-common Status: {{ model.filters.cbrStatus | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.cbrUser.length"
    @click:close="removeFilterMultiple({field: 'cbrUser'})"
  ).chip-common CBR Users: {{ CBRUsers | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.licenseType.length"
    @click:close="removeFilterMultiple({field: 'licenseType'})"
  ).chip-common License type: {{ model.filters.licenseType | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examProduct.length"
    @click:close="removeFilterMultiple({field: 'examProduct'})"
  ).chip-common Product: {{ model.filters.examProduct | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.fixedDates !== undefined && model.filters.fixedDates !== null"
    @click:close="removeFilter({field: 'fixedDates'})"
  ).chip-common Fixed dates: {{ model.filters.fixedDates | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.lastError"
    @click:close="removeFilter({field: 'lastError'})"
  ).chip-common Last error: {{ model.filters.lastError }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.hasAvaliableSlots"
    @click:close="removeFilter({field: 'hasAvaliableSlots'})"
  ).chip-common Available slots: {{ model.filters.hasAvaliableSlots | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examRequestsCount !== null && model.filters.examRequestsCount > -1"
    @click:close="removeFilter({field: 'examRequestsCount'})"
  ).chip-common Exam requests count: {{ model.filters.examRequestsCount }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueExamLocation.length"
    @click:close="removeFilterMultiple({field: 'queueExamLocation'})"
  ).chip-common Queue exam location: {{ model.filters.queueExamLocation | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.city.length"
    @click:close="removeFilterMultiple({field: 'city'})"
  ).chip-common Exam location: {{ model.filters.city | normalizeListWithComma }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueExamDateAfter"
    @click:close="removeFilter({field: 'queueExamDateAfter'})"
  ).chip-common Queue date from: {{ model.filters.queueExamDateAfter | formattedDate }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueExamDateBefore"
    @click:close="removeFilter({field: 'queueExamDateBefore'})"
  ).chip-common Queue date to: {{ model.filters.queueExamDateBefore | formattedDate}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueExamDateStrict"
    @click:close="removeFilter({field: 'queueExamDateStrict'})"
  ).chip-common Queue date strict mode: {{ model.filters.queueExamDateStrict | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examDateAfter"
    @click:close="removeFilter({field: 'examDateAfter'})"
  ).chip-common Exam date from: {{ model.filters.examDateAfter | formattedDate }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examDateBefore"
    @click:close="removeFilter({field: 'examDateBefore'})"
  ).chip-common Exam date to: {{ model.filters.examDateBefore | formattedDate }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.courseDateAfter"
    @click:close="removeFilter({field: 'courseDateAfter'})"
  ).chip-common Course date from: {{ model.filters.courseDateAfter | formattedDate }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.courseDateBefore"
    @click:close="removeFilter({field: 'courseDateBefore'})"
  ).chip-common Course date to: {{ model.filters.courseDateBefore | formattedDate }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examTimeAfter"
    @click:close="removeFilter({field: 'examTimeAfter'})"
  ).chip-common Exam time from: {{ model.filters.examTimeAfter }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.examTimeBefore"
    @click:close="removeFilter({field: 'examTimeBefore'})"
  ).chip-common Exam time to: {{ model.filters.examTimeBefore }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueTimeAfter"
    @click:close="removeFilter({field: 'queueTimeAfter'})"
  ).chip-common Queue time from: {{ model.filters.queueTimeAfter }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.queueTimeBefore"
    @click:close="removeFilter({field: 'queueTimeBefore'})"
  ).chip-common Queue time to: {{ model.filters.queueTimeBefore }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.failed !== undefined && modequeueTimeAfterl.filters.failed !== null"
    @click:close="removeFilter({field: 'failed'})"
  ).chip-common Failed: {{ model.filters.failed | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.inQueue !== undefined && model.filters.inQueue !== null"
    @click:close="removeFilter({field: 'inQueue'})"
  ).chip-common Is in Queue: {{ model.filters.inQueue | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.wasExchanged !== undefined && model.filters.wasExchanged !== null"
    @click:close="removeFilter({field: 'wasExchanged'})"
  ).chip-common Was exchanged: {{ model.filters.wasExchanged | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.inExchangeQueue !== undefined && model.filters.inExchangeQueue !== null"
    @click:close="removeFilter({field: 'inExchangeQueue'})"
  ).chip-common Is in exchange queue: {{ model.filters.inExchangeQueue | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.isCoursePaid !== undefined && model.filters.isCoursePaid !== null"
    @click:close="removeFilter({field: 'isCoursePaid'})"
  ).chip-common Is course paid: {{ model.filters.isCoursePaid | booleanToYN}}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.isNotPaidOnline !== undefined && model.filters.isNotPaidOnline !== null"
    @click:close="removeFilter({field: 'isNotPaidOnline'})"
  ).chip-common Is online course paid: {{ !model.filters.isNotPaidOnline | booleanToYN }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.courseType !== undefined && model.filters.courseType !== null"
    @click:close="removeFilter({field: 'courseType'})"
  ).chip-common Course type: {{ model.filters.courseType }}
  v-chip(outlined color='primary' close close-icon="mdi-close"
    v-if="model.filters.id"
    @click:close="removeFilter({field: 'id'})"
  ).chip-common Search ID: {{ model.filters.id }}
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      getCBRUserBy: 'cbrUser/getNameByID',
      cities: 'crmCourseCities/items'
    }),

    CBRUsers() {
      return this.model.filters.cbrUser.map(userID => this.getCBRUserBy(userID))
    },

    courseLocations() {
      if(!this.cities) return []
      return this.model.filters.courseCity.map(cityID => {
        let sameCity = this.cities.find(city => +city.ID === +cityID)
        if(!sameCity) return
        return sameCity.name
      })
    }
  },

  methods: {
    removeFilterMultiple({field}) {
      this.$emit('remove:multiple', field)
    },

    removeFilter({field}) {
      this.$emit('remove', field)
    },
  }
}
</script>

<style lang="scss">
.chip-common {
  margin-top: 16px;
  font-weight: bold;
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
